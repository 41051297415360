import { Component, OnInit } from '@angular/core';
import { ContentService } from "../_service/content.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-edit-content',
  templateUrl: './edit-content.component.html',
  styleUrls: ['./edit-content.component.css'],
  providers: [ContentService]
})
export class EditContentComponent implements OnInit {

  ContentEditForm: FormGroup;
  submitted = false;
  encrypt_content_id: any;
  content_id: any;
  content_details: any = {};
  details_type: any;
  back_url: any;

  editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
          {class: 'arial', name: 'Arial'},
          {class: 'times-new-roman', name: 'Times New Roman'},
          {class: 'calibri', name: 'Calibri'},
          {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: []
  };
  
  
  constructor(
    private formBuilder: FormBuilder,
    private _ContentService: ContentService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private _EncryptdecryptService: EncryptdecryptService
  ) { }

  ngOnInit(): void {
    this.encrypt_content_id = this.activatedRoute.snapshot.params.content_id;
    this.content_id = this._EncryptdecryptService.decryptUsingAES256(this.encrypt_content_id);

    this.ContentEditForm = this.formBuilder.group({
      description: ['', Validators.required]
    });

    this.getContentDetails();

  }

  get getContentEditFormVal() { return this.ContentEditForm.controls; }

  getContentDetails(){
    /** spinner starts on init */
    this.spinner.show();
    let params = {
      content_id: this.content_id
    }
    this._ContentService.getContentDetails(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.content_details = response.response;
          //this.divView.nativeElement.innerHTML = this.content_details.notification_description;
          // Set Values
          this.ContentEditForm.controls["description"].setValue(this.content_details.notification_description);

          if(this.content_details.notification_type==='SMS'){
            this.details_type = 'SMS';
            this.back_url     = ['/content_list/SMS'];
          }
          else{
            this.details_type = 'Email';
            this.back_url     = ['/content_list/Email'];
          }
        }
        console.log('response :: ',this.content_details);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.ContentEditForm.invalid) {
        return;
    }
    console.log(this.ContentEditForm.value);

    /** spinner starts on init */
    this.spinner.show(); 
    const content = this.ContentEditForm.value.description;

    let params = {
      "content": content,
      "content_id" : this.content_id
    }
    console.log('params : ', params);

    this._ContentService.editContentDetails(params).subscribe((response) => {
        //console.log('response : ', response.status);
        if(response.status === true){
          
          this.spinner.hide();
          this.toastr.success(response.status_msg);
        }
        else{
          this.spinner.hide();
          this.toastr.info(response.status_msg);
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

}
