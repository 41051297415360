import { Routes, RouterModule } from '@angular/router';


import { AuthComponent } from './Admin/auth/auth.component';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { AgentsComponent } from "./Admin/agents/agents_list/agents.component";
import { AgentDetailsComponent } from "./Admin/agents/agent-details/agent-details.component";
import { AuthGuardService } from "./Admin/auth/_service/auth-guard.service";
import { ListComponent } from "./Admin/listing/list/list.component";
import { DetailsComponent } from "./Admin/listing/details/details.component";
import { GroupsListComponent } from './Admin/groups/groups-list/groups-list.component';
import { GroupDetailsComponent } from './Admin/groups/group-details/group-details.component';
import { RevenueListComponent } from './Admin/payment/revenue-list/revenue-list.component';
import { TrycklPercentageComponent } from './Admin/payment/tryckl-percentage/tryckl-percentage.component';
import { NotificationListComponent } from './Admin/notification/notification-list/notification-list.component';
import { ContentListComponent } from './Admin/content/content-list/content-list.component';
import { ContentDetailsComponent } from './Admin/content/content-details/content-details.component';
import { EditContentComponent } from './Admin/content/edit-content/edit-content.component';
import { StaticContentComponent } from './Admin/content/static-content/static-content.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: AuthComponent,
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'agents',
        component: AgentsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'agent_details/:agent_id',
        component: AgentDetailsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'listing',
        component: ListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'listing_details/:listing_id',
        component: DetailsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'groups',
        component: GroupsListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'group_details/:group_id',
        component: GroupDetailsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'tryckl_percentage',
        component: TrycklPercentageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'revenue_list',
        component: RevenueListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'notification_list',
        component: NotificationListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'content_list/:type',
        component: ContentListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'content_details/:content_id',
        component: ContentDetailsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'content_edit/:content_id',
        component: EditContentComponent,
        canActivate: [AuthGuardService]
    },
    {
      path: 'static_content/:page',
      component: StaticContentComponent,
      canActivate: [AuthGuardService]
    }
];

export const AppRoutingModule = RouterModule.forRoot(routes);
