import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public title:string = '';

  constructor() {
   
    this.title = 'Hello World';
  }

  ngOnInit(): void {
    //window.location.reload();
    const d = sessionStorage.getItem('_loggedinuser_id');
    console.log('===Session Data ===');
    console.log(d);
  }

}
