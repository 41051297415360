import { Component, OnInit } from '@angular/core';
import { ListingService } from "../_service/listing.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [ListingService]
})
export class ListComponent implements OnInit {

  encypt_data:string = '';
  dtOptions: DataTables.Settings = {};
  listingList: any = [];

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering

  dtTrigger = new Subject();

  constructor(
    private _ListingService: ListingService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _EncryptdecryptService: EncryptdecryptService
    ) { }

  ngOnInit(): void {
    this.listingList = [];
    /** spinner starts on init */
    this.spinner.show();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };
    this.getList();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getList() {
    let params = {};
    this._ListingService.getListingList(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.listingList = response.response.list;
          this.dtTrigger.next();
          //console.log('==>> ',response.response);
        }
        console.log('Merchant List : ', this.listingList);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
    
  }

  redirctTo(id){
    id = parseInt(id);
    this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(id);
    this.router.navigate(['/listing_details/', this.encypt_data]);
  }
}
