<div class="wrapper theme-2-active navbar-top-light horizontal-nav">
    <app-header></app-header>
    <div *ngIf="is_loggedin===true" class="page-wrapper">
        <app-loader></app-loader>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>

    <!-- <div *ngIf="is_loggedin===true && is_dashboard===true" class="page-wrapper" style="height: 100vh;">
        <app-loader></app-loader>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div> -->

    <div *ngIf="is_loggedin===false">
        <router-outlet></router-outlet>
    </div>
</div>
