import { Component, OnInit } from '@angular/core';
import { AgentsService } from "../_service/agents.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
//import Swal from 'sweetalert2/dist/sweetalert2.js';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css'],
  providers: [AgentsService]
})
export class AgentsComponent implements OnInit {
  encypt_data:string = '';

  public APIEndpoint = environment.baseUrl;
  dtOptions: DataTables.Settings = {};
  current_agent: any = {};

  agentList: any = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger = new Subject();

  constructor(
    private _AgentsService: AgentsService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _EncryptdecryptService: EncryptdecryptService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.agentList = [];
    /** spinner starts on init */
    this.spinner.show();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };
    this.getAgentsList();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getAgentsList() {
    let params = {};
    this._AgentsService.getAgentsList(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.agentList = response.response.list;
          this.dtTrigger.next();
          //console.log('==>> ',response.response);
        }
        console.log('Merchant List : ', this.agentList);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
    
  }
  profileStatusChangeConfirm(no_of_attempts,id) {
    if(no_of_attempts =='3')
       {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to deactivate this Agent!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22af47',
      cancelButtonColor: '#f83f37',
      confirmButtonText: 'Yes, deactivate it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your agent has been deactivated.',
        //   'success'
        // )
        this.profileStatusChange(no_of_attempts,id);
      }
    });
  }
  else{
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to activate this Agent!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22af47',
      cancelButtonColor: '#f83f37',
      confirmButtonText: 'Yes, activate it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Activated!',
        //   'Your agent has been activated.',
        //   'success'
        // )
        this.profileStatusChange(no_of_attempts,id);
      }
    });
  }
  }
  profileStatusChange(no_of_attempts,id) {
    let params = {
    "no_of_attempts": no_of_attempts,
    "broker_id": id
    };
    
    this.spinner.show();
    this._AgentsService.profileStatusChange(params).subscribe((response) => {
      this.spinner.hide();
            this.router.navigate(['/agents']).then(() => {
        window.location.reload();
          }); 
    
    },
    (error) => {
      this.spinner.hide();
      console.log(error);
    }
  )
    
 
  }

  redirctTo(flag,id){
    if(flag==='Details'){
      //let path = '/merchants/'+id;
      this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(id);
      this.router.navigate(['/agent_details/', this.encypt_data]);
    }
  }

}
