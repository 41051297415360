<footer class="footer pl-30 pr-30">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <p>2021 &copy; Tryckl</p>
            </div>
            <!-- <div class="col-sm-6 text-right">
                <p>Follow Us</p>
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><i class="fa fa-google-plus"></i></a>
            </div> -->
        </div>
    </div>
</footer>