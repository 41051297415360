<div class="container" style="height: 75vh;">
  <!-- Title -->
  <div class="row heading-bg">
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <h5 class="txt-dark">Edit {{page_content_for}} </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
        <ol class="breadcrumb">
          <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
          <li class="active"><span>Edit {{page_content_for}}</span></li>
        </ol>
      </div>
      <!-- /Breadcrumb -->
  </div>
  <!-- /Title -->
  <!-- Row -->
  <div class="row">
      <div class="col-sm-12">
          <div class="panel panel-default border-panel card-view height-adj">
              <div class="panel-heading">
                  <div class="pull-left">
                      <h6 class="panel-title txt-dark">Edit {{page_content_for}}</h6>
                  </div>
                  <div class="clearfix"></div>
              </div>
              <div class="panel-wrapper collapse in">
                  <div class="panel-body">
                      <div class="form-wrap">
                          <form [formGroup]="ContentEditForm" autocomplete="off" (ngSubmit)="onSubmit()">
                              <div class="form-group">
                                  <label class="control-label mb-10" for="pwd_de"><strong>Note :</strong> </label>
                                  <br />
                                  <span > <strong>{{page_content_for}} content modification which will effect in app </strong></span>
                              </div>
                              <div class="form-group">
                                  <label class="control-label mb-10" for="pwd_de"><strong>Inner content</strong> : </label>

                                  <angular-editor formControlName="page_content" id="page_content" [config]="editorConfig"></angular-editor>


                                  <div class="alert alert-warning alert-dismissable" *ngIf="submitted && getContentEditFormVal.page_content.errors">
                                      <i class="zmdi zmdi-alert-circle-o pr-15 pull-left"></i>
                                      <p class="pull-left" *ngIf="getContentEditFormVal.page_content.errors.required">page_content can not be blank.</p>
                                      <div class="clearfix"></div>
                                  </div>
                              </div>

                              <div class="form-group" *ngIf="is_visiable">
                                  <label class="control-label mb-10" for="pwd_de"><strong>Inner content</strong> : </label>
                                  <span style="color:red;">(** Please don't change the text within curly braces)</span>
                                  <textarea class="form-control" formControlName="page_content" id="page_content" name="page_content"></textarea>

                                  <div class="alert alert-warning alert-dismissable" *ngIf="submitted && getContentEditFormVal.page_content.errors">
                                      <i class="zmdi zmdi-alert-circle-o pr-15 pull-left"></i>
                                      <p class="pull-left" *ngIf="getContentEditFormVal.page_content.errors.required">Page content can not be blank.</p>
                                      <div class="clearfix"></div>
                                  </div>
                              </div>
                              <div class="form-group space-bottom">
                                  <button type="submit" class="btn btn-orange btn-anim">
                                      <i class="icon-rocket"></i>
                                      <span class="btn-text">submit</span>
                                  </button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- /Row -->
</div>
