import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public APIEndpoint = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getLatestNotificationList() {
    const formData = new FormData();
    return this.http.post<any>(`${this.APIEndpoint}notification/admin_notification_list`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  readNotification(params) {
    const formData = new FormData();
    formData.append('notification_id', params.notification_id);

    return this.http.post<any>(`${this.APIEndpoint}notification/read_admin_notification`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  readMultipleNotification(params) {
    const formData = new FormData();
    let notification_ids = JSON.stringify(params.notification_ids);
    formData.append('notification_ids', notification_ids);
    
    return this.http.post<any>(`${this.APIEndpoint}notification/read_multiple_notification`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }
}
