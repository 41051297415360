import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  public APIEndpoint = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getAgentsList(params) {
    const formData = new FormData();

    return this.http.post<any>(`${this.APIEndpoint}broker/all_agents`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  getAgentsDetails(params) {
    const formData = new FormData();
    formData.append('brokers_id', params.agent_id);

    return this.http.post<any>(`${this.APIEndpoint}broker/agent_details`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }
  profileStatusChange(params) {
    const formData = new FormData();
    formData.append('no_of_attempts', params.no_of_attempts);
    formData.append('broker_id', params.broker_id);
    return this.http.post<any>(`${this.APIEndpoint}broker/activate_or_deactive_profile`, formData)
    .pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
  }

}
