import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'adminpanel';
  currentRoute: any;
  url: string;
  is_loggedin: boolean;
  is_dashboard: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
   
  }

  ngOnInit() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe(event => 
      {
        this.currentRoute = event;   
        this.url = this.currentRoute.url;       
        console.log(this.url);
        if(this.url === '/' || this.url=== '/login'){
          this.is_loggedin = false;
        }else{
          this.is_loggedin = true;
        } 

        if(this.url === '/dashboard'){
          this.is_dashboard = true;
        }
        else{
          this.is_dashboard = false;
        }

        console.log('==>> ', this.is_dashboard);
      });
  }
}
