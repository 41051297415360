<div class="container">
    <!-- Title -->
    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Agents detail</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><a href="index.html">Dashboard</a></li>
            <li>
                <a [routerLink]="['/agents/']">
                    <span>Agents</span>
                </a>
            </li>
            <li class="active"><span>Agents detail</span></li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div class="row">

                            <div class="col-md-3">
                                <div class="item-big">
                                    <!-- START carousel-->
                                    <div id="carousel-example-captions-1" data-ride="carousel" class="carousel slide">
                                        
                                        <div role="listbox" class="carousel-inner" *ngIf="agentDetails.profile_photo == '' || agentDetails.profile_photo == null">
                                            <div class="item active"> <img src="../../../../assets/img/noimg.png" alt="{{agentDetails.first_name}} {{agentDetails.last_name}}" style="width: 350px; height:350px;"> </div>
                                        </div>
                                        <div role="listbox" class="carousel-inner" *ngIf="agentDetails.profile_photo != '' && agentDetails.profile_photo != null">
                                           <div class="item active"> <img src="{{agentDetails.profile_photo}}" alt="{{agentDetails.first_name}} {{agentDetails.last_name}}" style="width: 350px; height:350px;"> </div>
                                        </div>
                                    </div>
                                    <!-- END carousel-->
                                </div>
                            </div>

                            <div class="col-md-5">
                                <div class="product-detail-wrap">
                                    
                                    <h4 class="mb-5 weight-500">{{agentDetails.first_name}} {{agentDetails.last_name}}</h4>
                                    <div class="product-price head-font mb-15">{{agentDetails.country_code}} - {{agentDetails.phone}}</div>
                                    <p class="mb-25"><strong>Email : </strong>{{agentDetails.email}}</p>
                                    <p class="mb-25"><strong>License No : </strong>{{agentDetails.license_no}}</p>
                                    <p class="mb-25"><strong>License Issuing State : </strong>{{agentDetails.license_issuing_state_name}}</p>
                                    <p class="mb-25"><strong>Brokerage Name : </strong>{{agentDetails.brokerage_name}}</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="product-detail-wrap">
                                    <p class="mb-25"><strong>Brokerage Office : </strong>{{agentDetails.brokerage_office_name}}</p>
                                    <p class="mb-25"><strong>State : </strong>{{agentDetails.state_name}}</p>
                                    <p class="mb-25"><strong>City : </strong>{{agentDetails.city}}</p>
                                    <p class="mb-25"><strong>Street Name : </strong>{{agentDetails.street_name}}</p>
                                    <p class="mb-25"><strong>Zipcode : </strong>{{agentDetails.zipcode}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Row -->

    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div  class="tab-struct custom-tab-1 product-desc-tab">
                            <ul role="tablist" class="nav nav-tabs nav-tabs-responsive" id="myTabs_7">
                                <li class="active" role="presentation">
                                    <a class="pt-0 pl-5 pr-5 pb-15" aria-expanded="true"  data-toggle="tab" role="tab" id="descri_tab" href="#groups_tab_detail">
                                        <span>My Groups</span>
                                    </a>
                                </li>

                                <li role="presentation" class="next"><a class="pt-0 pl-5 pr-5 pb-15" data-toggle="tab" id="adi_info_tab" role="tab" href="#listing_tab_detail" aria-expanded="false"><span>My Listing</span></a></li>

                                <li role="presentation" class="">
                                    <a class="pt-0 pl-5 pr-5 pb-15" data-toggle="tab" id="review_tab" role="tab" href="#card_tab_detail" aria-expanded="false">
                                        <span>My Card</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent_7">
                                <div  id="groups_tab_detail" class="tab-pane fade active in pt-0" role="tabpanel">
                                    <br/>
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table table-hover display  pb-30" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                                <thead>
                                                    <tr>
                                                        <th>Group Name</th>
                                                        <th>Created On</th>
                                                        <th style="text-align: center;">No. Of Members</th>
                                                        <th style="text-align: center;">Status</th>
                                                        <th style="text-align: center;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Group Name</th>
                                                        <th>Created On</th>
                                                        <th style="text-align: center;">No. Of Members</th>
                                                        <th style="text-align: center;">Status</th>
                                                        <th style="text-align: center;">Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr *ngFor="let list of myGroupList; let i = index">
                                                        <td>{{list.group_name}}</td>
                                                        <td>{{list.created_at | date:'medium'}}</td>
                                                        <td style="text-align: center;">{{list.total_members}}</td>
                                                        <td style="text-align: center;">
                                                            
                                                            <span *ngIf="list.status;else other_content" 
                                                            class="label label-success">Active</span>
                                                            <ng-template #other_content>
                                                                <span class="label label-danger">Inactive</span>
                                                            </ng-template>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            
                                                            <a (click) = "redirectTo('Group',list.group_id)" class="mr-25" data-toggle="tooltip" data-original-title="View">
                                                                <i class="fa fa-eye text-inverse m-r-10"></i> 
                                                            </a> 

                                                            <a *ngIf="list.status==0" data-toggle="tooltip" data-original-title="Delete">
                                                                <i class="fa fa-close text-danger"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div  id="listing_tab_detail" class="tab-pane pt-0 fade" role="tabpanel">
                                    <br />
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table table-hover display  pb-30"  datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2">
                                                <thead>
                                                    <tr>
                                                        <th>Card No.</th>
                                                        <th>Created On</th>
                                                        <th>Location</th>
                                                        <th>Accepted By</th>
                                                        <th>Amount</th>
                                                        <th>Listing Status</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Card No.</th>
                                                        <th>Created On</th>
                                                        <th>Location</th>
                                                        <th>Accepted By</th>
                                                        <th>Amount</th>
                                                        <th>Listing Status</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr *ngFor="let listing of myListing; let j = index">
                                                        <td>{{listing.card_no}}</td>
                                                        <td>{{listing.created_at | date:'medium'}}</td>
                                                        <td>{{listing.property_address}}</td>
                                                        <td>{{listing.accepted_by_name}}</td>
                                                        <td>{{listing.offer_amount}}</td>
                                                        <td style="text-align: center;">{{listing.listing_status}}</td>
                                                        <td style="text-align: center;">
                                                            
                                                            <span *ngIf="listing.status;else other_content" 
                                                            class="label label-success">Active</span>
                                                            <ng-template #other_content>
                                                                <span class="label label-danger">Inactive</span>
                                                            </ng-template>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            
                                                            <a (click) = "redirectTo('Listing',listing.listing_id)" class="mr-25" data-toggle="tooltip" data-original-title="View">
                                                                <i class="fa fa-eye text-inverse m-r-10"></i> 
                                                            </a> 

                                                            <a *ngIf="listing.status==0" data-toggle="tooltip" data-original-title="Delete">
                                                                <i class="fa fa-close text-danger"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div  id="card_tab_detail" class="tab-pane pt-0 fade" role="tabpanel">
                                    <br />
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table table-hover display  pb-30" datatable [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger3">
                                                <thead>
                                                    <tr>
                                                        <th>Card No.</th>
                                                        <th>Created On</th>
                                                        <th>Location</th>
                                                        <th>Created By</th>
                                                        <th>Amount</th>
                                                        <th>Card Status</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Card No.</th>
                                                        <th>Created On</th>
                                                        <th>Location</th>
                                                        <th>Created By</th>
                                                        <th>Amount</th>
                                                        <th>Card Status</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr *ngFor="let card of myCard; let k = index">
                                                        <td>{{card.card_no}}</td>
                                                        <td>{{card.created_at | date:'medium'}}</td>
                                                        <td>{{card.property_address}}</td>
                                                        <td>{{card.brokers_name}}</td>
                                                        <td>{{card.offer_amount}}</td>
                                                        <td style="text-align: center;">{{card.listing_status}}</td>
                                                        <td style="text-align: center;">
                                                            
                                                            <span *ngIf="card.status;else other_content" 
                                                            class="label label-success">Active</span>
                                                            <ng-template #other_content>
                                                                <span class="label label-danger">Inactive</span>
                                                            </ng-template>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            
                                                            <a (click) = "redirectTo('Card',card.listing_id)" class="mr-25" data-toggle="tooltip" data-original-title="View">
                                                                <i class="fa fa-eye text-inverse m-r-10"></i> 
                                                            </a> 

                                                            <a *ngIf="card.status==0" data-toggle="tooltip" data-original-title="Delete">
                                                                <i class="fa fa-close text-danger"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div  class="tab-struct custom-tab-1 product-desc-tab">
                            <ul role="tablist" class="nav nav-tabs nav-tabs-responsive" id="myTabs_7">
                                <li class="active" role="presentation" class="">
                                    <a class="pt-0 pl-5 pr-5 pb-15" data-toggle="tab" id="review_tab" role="tab" href="#settings_tab_detail" aria-expanded="false">
                                        <span><strong>Settings</strong></span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent_7">
                                <div id="settings_tab_detail" class="tab-pane fade active in pt-0" role="tabpanel">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                          <table class="table  mb-0">
                                            <tbody>
                                                <tr>
                                                    <td class="border-none" width="25%">Push Notification</td>
                                                    <td class="border-none" style="text-align: left;">{{agentDetails.push_notification}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none">Email Notification</td>
                                                    <td class="border-none">{{agentDetails.email_notification}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none">Rating</td>
                                                    <td class="border-none">
                                                        <div class="product-rating inline-block mb-10" *ngIf="agentDetails.setting_rating=='1.00' || agentDetails.setting_rating=='1'">
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                        </div>

                                                        <div class="product-rating inline-block mb-10" *ngIf="agentDetails.setting_rating=='2.00' || agentDetails.setting_rating=='2'">
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                        </div>

                                                        <div class="product-rating inline-block mb-10" *ngIf="agentDetails.setting_rating=='3.00' || agentDetails.setting_rating=='3'">
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                        </div>

                                                        <div class="product-rating inline-block mb-10" *ngIf="agentDetails.setting_rating=='4.00' || agentDetails.setting_rating=='4'">
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                        </div>

                                                        <div class="product-rating inline-block mb-10" *ngIf="agentDetails.setting_rating=='5.00' || agentDetails.setting_rating=='5'">
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star"></a>
                                                        </div>

                                                        <div class="product-rating inline-block mb-10" *ngIf="agentDetails.setting_rating=='0.00' || agentDetails.setting_rating=='0'">
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                            <a href="javascript:void(0);" class="zmdi zmdi-star-outline"></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                    </div>
                                    <br />
                                    <strong>Customize Details</strong>
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table table-hover display  pb-30">
                                                <thead>
                                                    <tr>
                                                        <th>Distance</th>
                                                        <th>Offers</th>
                                                        <th>Time Options</th>
                                                        <th>Zipcode</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Distance</th>
                                                        <th>Offers</th>
                                                        <th>Time Options</th>
                                                        <th>Zipcode</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr *ngFor="let set_list of settingsDetails; let i = index">
                                                        <td>{{set_list.distance==''?'No Distance':set_list.distance}}</td>
                                                        <td>
                                                            <div *ngFor="let offersList of set_list.offers; let j=index">
                                                                <span *ngIf="offersList=='All Offers';else other_offers">
                                                                    {{offersList}} (Showing, Open House, Contractor Assistance)
                                                                </span>
                                                                <ng-template #other_offers>
                                                                    <span>
                                                                        {{offersList}}
                                                                    </span>
                                                                </ng-template>
                                                            </div>
                                                        </td>
                                                        <td>{{set_list.time_set}}</td>
                                                        <td>{{set_list.zipcode==''?'No Zipcode':set_list.zipcode}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Row -->
    
    
</div>