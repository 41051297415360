<div class="container">
    <!-- Title -->
    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Group detail</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
            <li>
                <a [routerLink]="['/groups/']">
                    <span>Groups</span>
                </a>
            </li>
            <li class="active"><span>Group details</span></li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="item-big">
                                    <!-- START carousel-->
                                    <div id="carousel-example-captions-1" data-ride="carousel" class="carousel slide">
                                        
                                        <div role="listbox" class="carousel-inner" *ngIf="groupDetails.group_photo == '' || groupDetails.group_photo == null">
                                            <div class="item active"> <img src="../../../../assets/img/noimg.png" alt="{{groupDetails.group_name}}" style="width: 350px;"> </div>
                                        </div>
                                        <div role="listbox" class="carousel-inner" *ngIf="groupDetails.group_photo != '' && groupDetails.group_photo != null">
                                           <div class="item active"> <img src="{{groupDetails.group_photo}}" alt="{{groupDetails.group_name}}" style="width: 350px;"> </div>
                                        </div>
                                    </div>
                                    <!-- END carousel-->
                                </div>
                            </div>

                            <div class="col-md-9">
                                <div class="product-detail-wrap">
                                    <h4 class="mb-5 weight-500"><strong>Group Name : </strong>{{groupDetails.group_name}}</h4>
                                    <p class="mb-25"><strong>Group Owner Name : </strong>{{groupDetails.group_owner_name}}</p>
                                    <p class="mb-25"><strong>Group Created Date : </strong>{{groupDetails.created_at | date:'medium'}}</p>
                                    <p class="mb-25"><strong>Total Group Members: </strong>{{total_group_member}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Row -->

    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div  class="tab-struct custom-tab-1 product-desc-tab">
                            <ul role="tablist" class="nav nav-tabs nav-tabs-responsive" id="myTabs_7">
                                <li class="active" role="presentation" class="">
                                    <a class="pt-0 pl-5 pr-5 pb-15" data-toggle="tab" id="review_tab" role="tab" href="#members_tab_detail" aria-expanded="false">
                                        <span><strong>Members</strong></span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent_7">
                                <div id="members_tab_detail" class="tab-pane fade active in pt-0" role="tabpanel">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table table-hover display  pb-30" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Brokerage Office Name</th>
                                                        <th>Rating</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Brokerage Office Name</th>
                                                        <th>Rating</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr *ngFor="let list of groupMembersList; let i = index">
                                                        <td>{{list.full_name}}</td>
                                                        <td>{{list.brokerage_office_name}}</td>
                                                        <td>{{list.rating}}</td>
                                                        <td>
                                                            <a (click)="redirctToAgentDetails(list.agent_id)" class="mr-25" data-toggle="tooltip" data-original-title="View">
                                                                <i class="fa fa-eye text-inverse m-r-10"></i> 
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Row -->
</div>