import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";
import { GroupsService } from "../_service/groups.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.css']
})
export class GroupDetailsComponent implements OnInit {

  group_id:string = '';
  encypt_group_id:string = '';
  total_group_member:number;
  encypt_agent_id:string = '';

  groupDetails: any = {};
  groupMembersList: any = [];

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private _EncryptdecryptService: EncryptdecryptService,
    private _GroupsService: GroupsService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.encypt_group_id = this.activatedRoute.snapshot.params.group_id;
    this.group_id = this._EncryptdecryptService.decryptUsingAES256(this.encypt_group_id);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };

    this.getCustomerDetails();

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getCustomerDetails(){
    /** spinner starts on init */
    this.spinner.show();
    let params = {
      group_id: this.group_id
    }
    this._GroupsService.getGroupDetails(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.groupDetails = response.response;

          this.groupMembersList = this.groupDetails.members;
          this.total_group_member = this.groupMembersList.length;
          this.dtTrigger.next();

        }
        console.log('response :: ',this.groupDetails);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

  redirctToAgentDetails(id){
      id = parseInt(id);
      //let path = '/merchants/'+id;
      this.encypt_agent_id = this._EncryptdecryptService.encryptUsingAES256(id);
      this.router.navigate(['/agent_details/', this.encypt_agent_id]);
    
  }

}
