import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  public APIEndpoint = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getContentList(params) {
    const formData = new FormData();
    formData.append('type', params.type);

    return this.http.post<any>(`${this.APIEndpoint}masterdata/get_content`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  getContentDetails(params) {
    const formData = new FormData();
    formData.append('content_id', params.content_id);

    return this.http.post<any>(`${this.APIEndpoint}masterdata/get_content_details`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  editContentDetails(params) {
    const formData = new FormData();
    formData.append('content', params.content);
    formData.append('content_id', params.content_id);

    return this.http.post<any>(`${this.APIEndpoint}masterdata/edit_notification_content`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }
  /** Method name - getStaticContent
   * Method Description - Fetch terms and conditions, Privacy policy & Payment Policy Data
   * Method Created By - Saikat Pal(UTL)
   * Method Created At - 29/11/2022
  */
  getStaticContent(params) {
    return this.http.post<any>(`${this.APIEndpoint}/api/v1/broker/get_company_details`,params).pipe(map((response: Response)=>{
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
         throw(err)
      })
    );

  }
  /** Method name - staticContent
   * Method Description - Update terms and conditions, Privacy policy & Payment Policy Data
   * Method Created By - Saikat Pal(UTL)
   * Method Created At - 28/11/2022
  */
  staticContent(params) {
    const formData = new FormData();
    formData.append('content_type',params.content_type);
    formData.append('content',params.content);

    return this.http.post<any>(`${this.APIEndpoint}/api/v1/broker/update_company_details`,formData).pipe(map((response: Response) => {
      let responseAny:any= response;
       return responseAny;
    }),
      catchError((err)=>{
        throw(err)
      })
    )

  }
}
