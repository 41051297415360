import { Component, OnInit } from '@angular/core';
import { PaymentService } from "../_service/payment.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tryckl-percentage',
  templateUrl: './tryckl-percentage.component.html',
  styleUrls: ['./tryckl-percentage.component.css'],
  providers: [PaymentService]
})
export class TrycklPercentageComponent implements OnInit {

  tryckl_percentage:string = '';
  percentageForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private _PaymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.percentageForm = this.formBuilder.group({
      tryckl_percentage: ['', Validators.required]
    });

    /** spinner starts on init */
    this.spinner.show();
    this.getTrycklPercentage();
  }

  get getPercentageFormVal() { return this.percentageForm.controls; }

  getTrycklPercentage(){

    this._PaymentService.getTrycklPercentage().subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          console.log('==>> ', response.response);
          this.tryckl_percentage = response.response.tryckl_percentage;
          // Set Values
          this.percentageForm.controls["tryckl_percentage"].setValue(this.tryckl_percentage);
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.percentageForm.invalid) {
        return;
    }
    console.log(this.percentageForm.value);

    /** spinner starts on init */
    this.spinner.show(); 
    const tryckl_percentage = this.percentageForm.value.tryckl_percentage;

    let params = {
      "tryckl_percentage": tryckl_percentage
    }
    console.log('params : ', params);
    this._PaymentService.saveTrycklPercentage(params).subscribe((response) => {
        console.log('response : ', response.status);
        if(response.status === true){
          
          this.spinner.hide();
          this.toastr.success(response.status_msg);
        }
        else{
          this.spinner.hide();
          this.toastr.info(response.status_msg);
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

}
