<div class="container" style="height: 75vh;">
    <!-- Title -->
    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">{{details_type}} details</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
            <li>
                <a routerLink="{{back_url}}">
                    <span>{{details_type}} List</span>
                </a>
            </li>
            <li class="active"><span>{{details_type}} details</span></li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table  mb-0">
                                            <tbody>
                                                <tr>
                                                    <td class="border-none" width="25%"><strong>Purpose</strong></td>
                                                    <td class="border-none" style="text-align: left;">
                                                        <span *ngIf="content_details.notification_type==='EMAIL'"><strong>{{content_details.email_type}} :</strong></span> {{content_details.notification_purpose}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Description</strong></td>
                                                    <td class="border-none">
                                                        <div #myDiv></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Created On</strong></td>
                                                    <td class="border-none">{{content_details.created_on | date:'medium'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Row -->   
    
</div>