import { Component, OnInit } from '@angular/core';
import { PaymentService } from "../_service/payment.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-revenue-list',
  templateUrl: './revenue-list.component.html',
  styleUrls: ['./revenue-list.component.css'],
  providers: [PaymentService]
})
export class RevenueListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  paymentList: any = [];

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering

  dtTrigger = new Subject();

  encypt_id: string = '';

  constructor(
    private _PaymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private _EncryptdecryptService: EncryptdecryptService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.paymentList = [];
    /** spinner starts on init */
    this.spinner.show();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };

    this.getList();   
  } 

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getList() {
    
    this._PaymentService.getRevenueList().subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.paymentList = response.response;
          this.dtTrigger.next();
          //console.log('==>> ',response.response);
        }
        console.log('Payment List : ', this.paymentList);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
    
  }

  redirctTo(id, flag){
    if(flag==='Listing'){
      id = parseInt(id);
      this.encypt_id = this._EncryptdecryptService.encryptUsingAES256(id);
      this.router.navigate(['/listing_details/', this.encypt_id]);
    }
    else if(flag === 'Agent'){
      id = parseInt(id);
      this.encypt_id = this._EncryptdecryptService.encryptUsingAES256(id);
      this.router.navigate(['/agent_details/', this.encypt_id]);
    }
  }
}
