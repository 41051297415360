<!-- Loader -->

<!-- <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#fff"
        type="ball-scale-multiple"
        >
        <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner> -->
    
    <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#fff"
        type="off">
        <img src='../../../../assets/img/tryckl-loader.gif' />
    </ngx-spinner>
<!-- /Loader -->