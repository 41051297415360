import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS,  } from '@angular/common/http';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from "ngx-spinner";
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppComponent } from './app.component';
import { AuthComponent } from './Admin/auth/auth.component';
import { HeaderComponent } from './Admin/common/header/header.component';
import { FooterComponent } from './Admin/common/footer/footer.component';
import { AppRoutingModule } from './app.routing';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { AgentsComponent } from './Admin/agents/agents_list/agents.component';
import { AgentDetailsComponent } from './Admin/agents/agent-details/agent-details.component';
import { ListComponent } from './Admin/listing/list/list.component';
import { DetailsComponent } from './Admin/listing/details/details.component';
import { LoaderComponent } from './Admin/common/loader/loader.component';
import { GroupsListComponent } from './Admin/groups/groups-list/groups-list.component';
import { GroupDetailsComponent } from './Admin/groups/group-details/group-details.component';
import { RevenueListComponent } from './Admin/payment/revenue-list/revenue-list.component';
import { TrycklPercentageComponent } from './Admin/payment/tryckl-percentage/tryckl-percentage.component';
import { NotificationListComponent } from './Admin/notification/notification-list/notification-list.component';
import { ContentListComponent } from './Admin/content/content-list/content-list.component';
import { ContentDetailsComponent } from './Admin/content/content-details/content-details.component';
import { EditContentComponent } from './Admin/content/edit-content/edit-content.component';

import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { StaticContentComponent } from './Admin/content/static-content/static-content.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    AgentsComponent,
    AgentDetailsComponent,
    ListComponent,
    DetailsComponent,
    LoaderComponent,
    GroupsListComponent,
    GroupDetailsComponent,
    RevenueListComponent,
    TrycklPercentageComponent,
    NotificationListComponent,
    ContentListComponent,
    ContentDetailsComponent,
    EditContentComponent,
    StaticContentComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxCaptchaModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule,
    DataTablesModule,
    AngularEditorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
