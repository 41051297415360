<div class="container">
    <!-- Title -->
    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Notification</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <ol class="breadcrumb">
              <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
              <li class="active"><span>Notification</span></li>
            </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    

    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default border-panel card-view">
                <div class="panel-heading">
                    <div class="pull-left">
                        <h6 class="panel-title txt-dark">Notification
                            <button class="label label-success" title="Read" *ngIf="readAllBtnShow===true" (click)="readMultipleNotification()">
                                <i class="icon-check"></i> Mark As Read
                            </button>
                        </h6>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="panel-wrapper collapse in">
                    <div class="panel-body">
                        <div class="table-wrap">
                            <div class="table-responsive">
                                <table datatable class="table table-hover display  pb-30" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox" [(ngModel)]="isMasterSel" name="list_name" value="h1" (change)="checkUncheckAll()"/>
                                            </th>
                                            <th>Name</th>
                                            <th>Content</th>
                                            <th>Date & Time</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>
                                                <input type="checkbox" [(ngModel)]="isMasterSel" name="list_name" value="h1" (change)="checkUncheckAll()"/>
                                            </th>
                                            <th>Name</th>
                                            <th>Content</th>
                                            <th>Date & Time</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr *ngFor="let list of notification_list; let j = index" >
                                            <td>
                                                <input type="checkbox" [(ngModel)]="list.isSelected" name="list_name" value="{{list.notification_id}}" (change)="isAllSelected()"/>
                                            </td>
                                            <td (click) = "redirctTo(j, list)">
                                                {{list.sender_name}}
                                                <span class="label label-warning pull-right" *ngIf="list.view_status==='0'">Unread</span>
                                            </td>
                                            <td (click) = "redirctTo(j, list)">{{list.text_message}}</td>
                                            <td (click) = "redirctTo(j, list)">
                                                <span  class="time-chat-history inline-block">{{list.posted_at}}</span>
                                            </td>
                                            <td>
                                                <!-- <button class="btn btn-default btn-icon-anim btn-circle" *ngIf="list.view_status==='0'" title="Read" (click)="readNotification(j, list)">
                                                    <i class="icon-check"></i>
                                                </button> -->
                                                <button class="label label-success" *ngIf="list.view_status==='0'" title="Read" (click)="readNotification(j, list)">
                                                    <i class="icon-check"></i> Mark As Read
                                                </button>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                               
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>	
        </div>
    </div>
    <!-- /Row -->
</div>
