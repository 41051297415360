<div class="container">
    <!-- Title -->
    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Groups List</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
            <li class="active"><span>Groups List</span></li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default border-panel card-view">
                <div class="panel-heading">
                    <div class="pull-left">
                        <h6 class="panel-title txt-dark">Groups List</h6>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="panel-wrapper collapse in">
                    <div class="panel-body">
                        <div class="table-wrap">
                            <div class="table-responsive">
                                <table datatable class="table table-hover display  pb-30" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>Group Name</th>
                                            <th>Created On</th>
                                            <th>Created By</th>
                                            <th style="text-align: center;">Group Status</th>
                                            <th style="text-align: center;">No. Of Members</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Group Name</th>
                                            <th>Created On</th>
                                            <th>Created By</th>
                                            <th style="text-align: center;">Group Status</th>
                                            <th style="text-align: center;">No. Of Members</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr *ngFor="let list of groupList; let i = index">
                                            <td>{{list.group_name}}</td>
                                            <td>{{list.created_at | date:'medium'}}</td>
                                            <td>{{list.group_owner_name}}</td>
                                            <td style="text-align: center;">
                                                
                                                <span *ngIf="list.status;else other_content" 
                                                class="label label-success">Active</span>
                                                <ng-template #other_content>
                                                    <span class="label label-danger">Inactive</span>
                                                </ng-template>
                                            </td>
                                            <td style="text-align: center;">{{list.total_members}}</td>
                                            <td style="text-align: center;">
                                                
                                                <a (click)="redirctTo(list.group_id)" class="mr-25" data-toggle="tooltip" data-original-title="View">
                                                    <i class="fa fa-eye text-inverse m-r-10"></i> 
                                                </a> 
                                                <a *ngIf="list.status===0" data-toggle="tooltip" data-original-title="Delete">
                                                    <i class="fa fa-close text-danger"></i>
                                                </a>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                               
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>	
        </div>
    </div>
    <!-- /Row -->
    
</div>