import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";
import { ListingService } from "../_service/listing.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  listing_id:string = '';
  encypt_data:string = '';
  keyword:any = '';
  mls_details:any = [];
  completed_details:any = {};
  completed_details_exists:number = 0;

  listingDetails: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private _EncryptdecryptService: EncryptdecryptService,
    private _ListingService: ListingService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.encypt_data = this.activatedRoute.snapshot.params.listing_id;
    this.listing_id = this._EncryptdecryptService.decryptUsingAES256(this.encypt_data);
    this.getListingDetails();
  }

  getListingDetails(){
    /** spinner starts on init */
    this.spinner.show();
    let params = {
      listing_id: this.listing_id
    }
    this._ListingService.getListingDetails(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.listingDetails = response.response;
          this.keyword = (this.listingDetails.keyword).toString();
          this.mls_details = this.listingDetails.mls_details;
          this.completed_details = this.listingDetails.complete_details;
          if(Object.keys(this.completed_details).length === 0){
            this.completed_details_exists = 1;
          }else{
            this.completed_details_exists = 0;
          }
        }
        console.log('response :: ',response);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

}
