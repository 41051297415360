<div class="page-wrapper pa-0 ma-0 auth-page" >
  <div class="container">
    
    <div class="table-struct full-width full-height">
      <div class="table-cell vertical-align-middle auth-form-wrap">
        <div class="auth-form  ml-auto mr-auto no-float card-view pt-30 pb-30">
          <div class="row">
            <div class="col-sm-12 col-xs-12">
              <div class="mb-30">
                <h3 class="text-center txt-dark mb-10">Sign in to Tryckl</h3>
                <h6 class="text-center nonecase-font txt-grey">Enter your details below</h6>
              </div>	
              <div class="form-wrap">
                <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <label class="control-label mb-10" for="exampleInputEmail_2">Username</label>
                    <div class="clearfix"></div>
                    <input type="text" class="form-control" formControlName="username" id="exampleInputEmail_2" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted && getLoginVal.username.errors }" >
                    
                    <div *ngIf="submitted && getLoginVal.username.errors" class="invalid-feedback" style="color:red;">
                        <div *ngIf="getLoginVal.username.errors.required">Username is required</div>
                    </div>
                    <div class="clearfix"></div>
                  </div>

                  <div class="form-group">
                    <label class="pull-left control-label mb-10" for="exampleInputpwd_2">Password</label>
                    <div class="clearfix"></div>
                    <!-- <input type="password" class="form-control" formControlName="password" id="exampleInputpwd_2" [ngClass]="{ 'is-invalid': submitted && getLoginVal.password.errors }" style="width:370px !important"> -->
                    <input type="password" [pattern]="passwordPattern" class="form-control" formControlName="password" id="exampleInputpwd_2" [ngClass]="{ 'is-invalid': submitted && getLoginVal.password.errors }">
                    <div *ngIf="submitted && getLoginVal.password.errors" class="invalid-feedback" style="color:red;">
                      <div *ngIf="getLoginVal.password.errors.required">Password is required</div>
                      <div *ngIf="getLoginVal.password.errors.minlength">Password must be at least 8 characters</div>
                      <div *ngIf="getLoginVal.password.errors.maxlength">Password should be at most 16 characters</div>
                      <div *ngIf="getLoginVal.password.errors.pattern">
                        Password should be alphanumeirc with at least 8 characters and at most 16 characters containing at least 1 special character, 1 uppercase, 1 lower case, 1 numeric.
                      </div> 
                    </div>
                    <div class="clearfix"></div>
                  </div>

                  <div class="form-group">
                    <ngx-recaptcha2 formControlName="recaptcha" #captchaElem [siteKey]="siteKey" [ngClass]="{ 'is-invalid': submitted && getLoginVal.recaptcha.errors }">

                    </ngx-recaptcha2>

                    <div *ngIf="submitted && getLoginVal.recaptcha.errors" class="invalid-feedback" style="color:red;">
                        <div *ngIf="getLoginVal.recaptcha.errors.required">Select recaptcha</div>
                    </div>
                  </div>
                  <div class="form-group text-center" style="text-align: center !important;">
                    <br/>
                    <button type="submit" class="btn btn-danger btn-rounded">Sign In</button>
                  </div>
                </form>
              </div>
            </div>	
          </div>
        </div>
      </div>
    </div>
    
  </div>
  
</div>

