import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  public APIEndpoint = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getGroupsList(params) {
    const formData = new FormData();

    return this.http.post<any>(`${this.APIEndpoint}group/all_list`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  getGroupDetails(params) {
    const formData = new FormData();
    formData.append('group_id', params.group_id);

    return this.http.post<any>(`${this.APIEndpoint}group/group_details`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }
}
