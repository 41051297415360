import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from "./_service/auth.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: [
    "./auth.component.css"],
  providers: [AuthService]
})
export class AuthComponent implements OnInit {
  passwordPattern: any;
  loginForm: FormGroup;
  submitted = false;
  siteKey: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _AuthService: AuthService,
    private toastr: ToastrService
  ) {
    this.siteKey = '6LeF2u0ZAAAAAMMkr0e4GKojrTV0xxDa4nG-fUzS';
  }

  ngOnInit() {
    if (sessionStorage.getItem('_token') !== null) {
      this.router.navigate(['/dashboard'])
      .then(() => {
        window.location.reload();
      });
    }

    this.passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
    this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(this.passwordPattern)]],
        recaptcha: ['', Validators.required]
    });

    // this.loginForm = this.formBuilder.group({
    //     username: ['', Validators.required],
    //     password: ['', [Validators.required, Validators.minLength(6)]],
    //     recaptcha: ['', Validators.required]
    // });
  }

  get getLoginVal() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }
    console.log(this.loginForm.value);

    const username = this.loginForm.value.username;
    const pwd = this.loginForm.value.password;
    let params = {
      "username": username,
      "password": pwd
    }
    this._AuthService.login(params).subscribe((response) => {
        console.log('response : ', response.status);
        if(response.status === true){
          let res = response.response;
          if(sessionStorage.getItem('_token')) {
            sessionStorage.removeItem('_loggedinuser_id');
            sessionStorage.setItem('_loggedinuser_id', res.id);
            sessionStorage.setItem('_loggedinuser_name', res.full_name);
            sessionStorage.setItem('_token', res._jwtToken);
          } else {
            sessionStorage.setItem('_loggedinuser_id', res.id);
            sessionStorage.setItem('_loggedinuser_name', res.full_name);
            sessionStorage.setItem('_token', res._jwtToken);
          }
          this.router.navigate(['/dashboard'])
          .then(() => {
            window.location.reload();
          });
        }
        else{
          this.toastr.info(response.status_msg);
        }
        
      },
      (error) => {
        
        console.log(error);
      }
    )
    
  }

}
