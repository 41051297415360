<div class="container" style="height: 75vh;">
					
    <!-- Title -->
    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Set Tryckl Percentage</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
            <li class="active"><span>Set Tryckl Percentage</span></li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default border-panel card-view">
                <div class="panel-heading">
                    <div class="pull-left">
                        <h6 class="panel-title txt-dark">Set Tryckl Percentage</h6>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="panel-wrapper collapse in">
                    <div class="panel-body">
                        <div class="form-wrap">
                            <form [formGroup]="percentageForm" autocomplete="off" enctype="multipart/form-data" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label class="control-label mb-10" for="pwd_de">Set percentage of tryckl revenue :</label>
                                    <input type="text" class="form-control" formControlName="tryckl_percentage" id="tryckl_percentage" name="tryckl_percentage" />

                                    <div class="alert alert-warning alert-dismissable" *ngIf="submitted && getPercentageFormVal.tryckl_percentage.errors">
                                        <i class="zmdi zmdi-alert-circle-o pr-15 pull-left"></i>
                                        <p class="pull-left" *ngIf="getPercentageFormVal.tryckl_percentage.errors.required">Percentage can not be blank.</p>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <button type="submit" class="btn btn-orange btn-anim">
                                        <i class="icon-rocket"></i>
                                        <span class="btn-text">submit</span>
                                    </button>
                                </div>	
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>	
    <!-- /Row -->
    
</div>