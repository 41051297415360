import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  public APIEndpoint = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getTrycklPercentage() {
    const formData = new FormData();
    return this.http.post<any>(`${this.APIEndpoint}masterdata/get_tryckl_percentage`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  saveTrycklPercentage(params) {
    const formData = new FormData();
    formData.append('tryckl_percentage', params.tryckl_percentage);
    formData.append('updated_by', sessionStorage.getItem('_loggedinuser_id'));

    return this.http.post<any>(`${this.APIEndpoint}masterdata/save_tryckl_percentage`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }

  getRevenueList() {
    const formData = new FormData();

    return this.http.post<any>(`${this.APIEndpoint}payment/all_payment_list`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }
}
