import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public APIEndpoint = environment.baseUrl;

  constructor(private http: HttpClient) { }

  login(params) {
    const formData = new FormData();

    formData.append('user_name', params.username);
    formData.append('password', params.password);

    return this.http.post<any>(`${this.APIEndpoint}admin/login`, formData)
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }
}
