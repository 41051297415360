import { Component, OnInit } from '@angular/core';
import { ContentService } from "../_service/content.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css'],
  providers: [ContentService]
})
export class ContentListComponent implements OnInit {
  type:string = '';
  dtOptions: DataTables.Settings = {};
  content_list: any = [];
  dtTrigger = new Subject();
  encrypt_id: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _ContentService: ContentService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _EncryptdecryptService: EncryptdecryptService
  ) { }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.params.type;

    this.content_list = [];

    /** spinner starts on init */
    this.spinner.show();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };
    this.getContentList();
  }

  getContentList(){
    
    let params = {
      type: this.type==='Email'?'EMAIL':'SMS',
    }
    this._ContentService.getContentList(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.content_list = response.response.list;
          this.dtTrigger.next();
        }
        console.log('Content list : ',this.content_list);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
    
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  redirctTo(id, flag){
    id = parseInt(id);
    if(flag === 'view'){
      this.encrypt_id = this._EncryptdecryptService.encryptUsingAES256(id);
      this.router.navigate(['/content_details/', this.encrypt_id]);
    }
    else if(flag === 'edit'){
      this.encrypt_id = this._EncryptdecryptService.encryptUsingAES256(id);
      this.router.navigate(['/content_edit/', this.encrypt_id]);
    }
  }

}
