import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ContentService } from "../_service/content.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";

@Component({
  selector: 'app-content-details',
  templateUrl: './content-details.component.html',
  styleUrls: ['./content-details.component.css'],
  providers: [ContentService]
})
export class ContentDetailsComponent implements OnInit {
  encrypt_content_id: any;
  content_id: any;
  content_details: any = {};
  details_type: any;
  back_url: any;

  @ViewChild("myDiv") divView: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _ContentService: ContentService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _EncryptdecryptService: EncryptdecryptService
  ) { }

  ngOnInit(): void {
    this.encrypt_content_id = this.activatedRoute.snapshot.params.content_id;
    this.content_id = this._EncryptdecryptService.decryptUsingAES256(this.encrypt_content_id);
    this.getContentDetails();
  }

  getContentDetails(){
    /** spinner starts on init */
    this.spinner.show();
    let params = {
      content_id: this.content_id
    }
    this._ContentService.getContentDetails(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.content_details = response.response;
          this.divView.nativeElement.innerHTML = this.content_details.notification_description;
          if(this.content_details.notification_type==='SMS'){
            this.details_type = 'SMS';
            this.back_url     = ['/content_list/SMS'];
          }
          else{
            this.details_type = 'Email';
            this.back_url     = ['/content_list/Email'];
          }
        }
        console.log('response :: ',this.content_details);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

}
