<div class="container">
    <!-- Title -->
    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Listing details</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
            <li>
                <a [routerLink]="['/listing/']">
                    <span>Listing</span>
                </a>
            </li>
            <li class="active"><span>Listing details</span></li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table  mb-0">
                                            <tbody>
                                                <tr>
                                                    <td class="border-none" width="25%"><strong>Card No.</strong></td>
                                                    <td class="border-none" style="text-align: left;">{{listingDetails.card_no}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Type</strong></td>
                                                    <td class="border-none">{{listingDetails.type}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Property Address</strong></td>
                                                    <td class="border-none">{{listingDetails.property_address}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Listing Date Time (UTC)</strong></td>
                                                    <td class="border-none">{{listingDetails.listing_utc_datetime | date:'medium'}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Access Type</strong></td>
                                                    <td class="border-none">{{listingDetails.access_type}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Agent Instruction</strong></td>
                                                    <td class="border-none">{{listingDetails.agent_instruction==''?'-':listingDetails.agent_instruction}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Client Name</strong></td>
                                                    <td class="border-none">{{listingDetails.client_name==''?'-':listingDetails.client_name}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Client Number</strong></td>
                                                    <td class="border-none">{{listingDetails.client_number==''?'-':listingDetails.client_number}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Offer Amount</strong></td>
                                                    <td class="border-none">$ {{listingDetails.offer_amount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Keyword</strong></td>
                                                    <td class="border-none">{{keyword=='' ? '-': keyword}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Accepted By Name</strong></td>
                                                    <td class="border-none">
                                                        {{listingDetails.accepted_by_name=='' || listingDetails.accepted_by_name==null?'-':listingDetails.accepted_by_name}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none"><strong>Accepted Date</strong></td>
                                                    <td class="border-none" *ngIf="listingDetails.accept_date!='' && listingDetails.accept_date!=null">
                                                        {{listingDetails.accept_date | date:'medium'}}
                                                    </td>
                                                    <td class="border-none" *ngIf="listingDetails.accept_date=='' || listingDetails.accept_date==null">-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Mls Details</h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table table-hover display  pb-30">
                                            <thead>
                                                <tr>
                                                    <th>Mls Name:</th>
                                                    <th>Mls Link :</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let mls of mls_details; let i = index"> 
                                                    <td style="text-align: left; width:25%">{{mls.mls_name}}</td>
                                                    <td style="text-align: left;">{{mls.mls_link}}</td>
                                                </tr>
                                            </tbody>
                                            <!-- <tfoot>
                                                <tr>
                                                    <th>Mls Name:</th>
                                                    <th>Mls Link :</th>
                                                </tr>
                                            </tfoot> -->
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                    <div class="panel-body pt-0">
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Listing Complete Details</h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table  mb-0">
                                            <tbody *ngIf="completed_details_exists===1">
                                                <tr>
                                                    <td class="border-none" style="text-align: left;" colspan="2">
                                                        No records
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="completed_details_exists===0">
                                                <tr>
                                                    <td class="border-none" width="25%"><strong>Agent Instruction</strong></td>
                                                    <td class="border-none" style="text-align: left;">{{completed_details.agent_instruction}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none" width="25%"><strong>Address</strong></td>
                                                    <td class="border-none" style="text-align: left;">{{completed_details.address}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none" width="25%"><strong>Completed At</strong></td>
                                                    <td class="border-none" style="text-align: left;">{{completed_details.completed_at | date:'medium'}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="border-none" width="25%"  style="vertical-align: top;"><strong>Image</strong></td>
                                                    <td class="border-none" style="text-align: left;">
                                                        <img src="{{completed_details.complete_image}}" style="width:250px;">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Row -->   
    
</div>