import { Component, OnInit } from '@angular/core';
import { NotificationService } from "../_service/notification.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css'],
  providers: [NotificationService]
})
export class NotificationListComponent implements OnInit {

  encypt_data:string = '';
  dtOptions: DataTables.Settings = {};
  notification_list: any = [];
  isMasterSel:boolean;
  checked_id: any = [];
  readAllBtnShow: boolean = false;

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering

  dtTrigger = new Subject();

  constructor(
    private _NotificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _EncryptdecryptService: EncryptdecryptService
  ) { }

  ngOnInit(): void {
    this.isMasterSel = false;

    this.notification_list = [];

    /** spinner starts on init */
    this.spinner.show();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };
    this.getNotificationList();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  checkUncheckAll() {
    for (var i = 0; i < this.notification_list.length; i++) {
      this.notification_list[i].isSelected = this.isMasterSel;
    }
    this.checkNotificationId();
  }

  isAllSelected() {
    this.isMasterSel = this.notification_list.every(function(item:any) {
        return item.isSelected == true;
    });
    this.checkNotificationId();
  }

  checkNotificationId(){
    this.checked_id = [];
    for (var i = 0; i < this.notification_list.length; i++) {
      if(this.notification_list[i].isSelected == true){
        let notification_id = parseInt(this.notification_list[i].notification_id);
        this.checked_id.push(notification_id);
      }
    }
    
    this.checked_id = this.checked_id.filter((element:any, i: any) => i === this.checked_id.indexOf(element));
    
    if(this.checked_id.length === 0)
      this.readAllBtnShow = false;
    else
      this.readAllBtnShow = true;

  }

  getNotificationList(){
    
    this._NotificationService.getLatestNotificationList().subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.notification_list = response.response.list;
          this.dtTrigger.next();
        }
       
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
    
  }

  readNotification(index, obj){
    this.spinner.show();
    //console.log('obj : ',this.notification_list[index]);
    let notification_id = obj.notification_id; 
    let params = {
      notification_id : notification_id
    };

    this._NotificationService.readNotification(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          let notification_obj = this.notification_list[index];
          notification_obj.view_status = '1';
        }
        console.log('Notification List :: ',this.notification_list[index]);
      },
      (error) => {
        this.spinner.hide();
        console.log(error); 
      }
    )
  }

  readMultipleNotification(){
    if(this.checked_id.length>0){
      this.spinner.show();

      let params = {
        notification_ids : this.checked_id
      };
      let notification_id_arr =   this.checked_id;

      this._NotificationService.readMultipleNotification(params).subscribe((response) => {
          this.spinner.hide();
          if(response.status === true){
            this.notification_list.every(function(item:any) {
              let notification_id = parseInt(item.notification_id);
              
              if(notification_id_arr.includes(notification_id) == true){
                item.view_status = '1';
              }
              return item;
            });
          }
        },
        (error) => {
          this.spinner.hide();
          console.log(error); 
        }
      )
    }

  }

  redirctTo(index,obj){
    this.spinner.show();
    let notification_id = obj.notification_id; 
    let params = {
      notification_id : notification_id
    };

    this._NotificationService.readNotification(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          let notification_obj = this.notification_list[index];
          notification_obj.view_status = '1';

          console.log('obj :: ',obj);
          if(obj.link_type === 'LISTING_DETAILS'){
            let link_id = obj.link_id;
            link_id = parseInt(link_id);
            this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
            this.router.navigate(['/listing_details/', this.encypt_data])
            .then(() => {
              window.location.reload();
            });
          }
          else if(obj.link_type === 'GROUP_DETAILS' && obj.content_type !== 'JOIN_GROUP_INVITATION'){
            let link_id = obj.link_id;
            link_id = parseInt(link_id);
            this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
            this.router.navigate(['/group_details/', this.encypt_data])
            .then(() => {
              window.location.reload();
            });
          }
          else if(obj.link_type === 'BROKER_DETAILS'){
            let link_id = obj.link_id;
            link_id = parseInt(link_id);
            this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
            this.router.navigate(['/agent_details/', this.encypt_data])
            .then(() => {
              window.location.reload();
            });
          }
        }
        console.log('Notification List :: ',this.notification_list[index]);
      },
      (error) => {
        this.spinner.hide();
        console.log(error); 
      }
    )
  }

}
