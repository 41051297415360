<div class="container" style="height: 75vh;">

    <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Edit {{details_type}} details</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
            <li>
                <a routerLink="{{back_url}}">
                    <span>{{details_type}} List</span>
                </a>
            </li>
            <li class="active"><span>Edit {{details_type}} details</span></li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
    </div>
    <!-- /Title -->
    
    <!-- Row -->
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default border-panel card-view">
                <div class="panel-heading">
                    <div class="pull-left">
                        <h6 class="panel-title txt-dark">Edit {{details_type}} details</h6>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="panel-wrapper collapse in">
                    <div class="panel-body">
                        <div class="form-wrap">
                            <form [formGroup]="ContentEditForm" autocomplete="off" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label class="control-label mb-10" for="pwd_de"><strong>Purpose :</strong> </label>
                                    <br />
                                    <span *ngIf="content_details.notification_type==='EMAIL'"> <strong>{{content_details.email_type}} - </strong></span> {{content_details.notification_purpose}}
                                </div>
                                <div class="form-group" *ngIf="details_type==='Email'">
                                    <label class="control-label mb-10" for="pwd_de"><strong>Description</strong> : </label>
                                    <span style="color:red;">(** Please don't change the text within curly braces)</span>
                                    <!-- <input type="text" class="form-control" formControlName="description" id="description" name="description" /> -->

                                    <angular-editor formControlName="description" id="description" [config]="editorConfig"></angular-editor>


                                    <div class="alert alert-warning alert-dismissable" *ngIf="submitted && getContentEditFormVal.description.errors">
                                        <i class="zmdi zmdi-alert-circle-o pr-15 pull-left"></i>
                                        <p class="pull-left" *ngIf="getContentEditFormVal.description.errors.required">Description can not be blank.</p>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="details_type==='SMS'">
                                    <label class="control-label mb-10" for="pwd_de"><strong>Description</strong> : </label>
                                    <span style="color:red;">(** Please don't change the text within curly braces)</span>
                                    <textarea class="form-control" formControlName="description" id="description" name="description"></textarea>
                                    <!-- <angular-editor formControlName="description" id="description" [config]="editorConfig"></angular-editor> -->


                                    <div class="alert alert-warning alert-dismissable" *ngIf="submitted && getContentEditFormVal.description.errors">
                                        <i class="zmdi zmdi-alert-circle-o pr-15 pull-left"></i>
                                        <p class="pull-left" *ngIf="getContentEditFormVal.description.errors.required">Description can not be blank.</p>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <button type="submit" class="btn btn-orange btn-anim">
                                        <i class="icon-rocket"></i>
                                        <span class="btn-text">submit</span>
                                    </button>
                                </div>	
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>	
    <!-- /Row -->
    
</div>