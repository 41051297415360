import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LogoutService } from "../_service/logout.service";
import { NotificationService } from "../_service/notification.service";
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [LogoutService]
})
export class HeaderComponent implements OnInit {

  currentRoute: any;
  url: string;
  is_loggedin: boolean;
  loggedin_username: string;
  is_revenue_active: boolean = false;
  is_content_active: boolean = false;
  notificationList: any = [];
  unreadNotificationCounter: any = '0';
  encypt_data: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _LogoutService: LogoutService,
    private _NotificationService: NotificationService,
    private _EncryptdecryptService: EncryptdecryptService,
    private spinner: NgxSpinnerService
  ){
    console.log(router.url);
   }


  ngOnInit() {
    if (sessionStorage.getItem('_loggedinuser_name') !== null) {
      this.loggedin_username = sessionStorage.getItem('_loggedinuser_name');
    }

    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe(event =>
    {
      this.currentRoute = event;
      this.url = this.currentRoute.url;
      //console.log('url :: ',this.url);
      if(this.url === '/' || this.url=== '/login'){
        this.is_loggedin = false;
      }else{
        this.is_loggedin = true;
      }

      if(this.url==='/tryckl_percentage' || this.url==='/revenue_list'){
        this.is_revenue_active = true;
      }else{
        this.is_revenue_active = false;
      }

      if(this.url==='/content_list/Email' || this.url==='/content_list/SMS' || (this.url.includes("content_edit")) || (this.url.includes("content_details"))){
        this.is_content_active = true;
      }else{
        this.is_content_active = false;
      }

        //console.log();
    });


    this.getNotificationList();
    setInterval(() => {
      this.getNotificationList();
    }, 10000);


  }

  logout(){
    let params = {
      "user_id": sessionStorage.getItem('_loggedinuser_id')
    }
    this._LogoutService.logout(params).subscribe((response) => {
        sessionStorage.removeItem('_loggedinuser_id');
        sessionStorage.removeItem('_loggedinuser_name');
        sessionStorage.removeItem('_token');
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log(error);
      }
    )

  }

  getNotificationList(){
    if(this.is_loggedin){

      this._NotificationService.getLatestNotificationList().subscribe((response) => {
          if(response.status === true){
            this.notificationList = response.response.list;
            this.unreadNotificationCounter = response.response.unread_notification;
          }
          //console.log('Notification List :: ',this.notificationList);
          //console.log('Counter :: ',this.unreadNotificationCounter);
        },
        (error) => {
          console.log(error);
        }
      )
    }
  }

  redirect_nd_load(type){
    if(type === 'email_content'){
      this.router.navigate(['/content_list/Email'])
        .then(() => {
          window.location.reload();
        });
    }
    else if(type === 'sms_content'){
      this.router.navigate(['/content_list/SMS'])
        .then(() => {
          window.location.reload();
        });
    } else {
      this.router.navigate(['static_content/'+type]).then(() => {
        window.location.reload();
      });
    }

  }

  /*redirctTo(obj){
    console.log('obj :: ',obj);
    if(obj.link_type === 'LISTING_DETAILS'){
      let link_id = obj.link_id;
      link_id = parseInt(link_id);
      this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
      this.router.navigate(['/listing_details/', this.encypt_data]);
    }
    else if(obj.link_type === 'GROUP_DETAILS' && obj.content_type !== 'JOIN_GROUP_INVITATION'){
      let link_id = obj.link_id;
      link_id = parseInt(link_id);
      this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
      this.router.navigate(['/group_details/', this.encypt_data]);
    }
    else if(obj.link_type === 'BROKER_DETAILS'){
      let link_id = obj.link_id;
      link_id = parseInt(link_id);
      this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
      this.router.navigate(['/agent_details/', this.encypt_data]);
    }

  }*/

  redirctTo(index,obj){
    this.spinner.show();
    let notification_id = obj.notification_id;
    let params = {
      notification_id : notification_id
    };

    this._NotificationService.readNotification(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          console.log('obj :: ',obj);
          if(obj.link_type === 'LISTING_DETAILS'){
            let link_id = obj.link_id;
            link_id = parseInt(link_id);
            this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
            this.router.navigate(['/listing_details/', this.encypt_data])
            .then(() => {
              window.location.reload();
            });
          }
          else if(obj.link_type === 'GROUP_DETAILS' && obj.content_type !== 'JOIN_GROUP_INVITATION'){
            let link_id = obj.link_id;
            link_id = parseInt(link_id);
            this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
            this.router.navigate(['/group_details/', this.encypt_data])
            .then(() => {
              window.location.reload();
            });
          }
          else if(obj.link_type === 'BROKER_DETAILS'){
            let link_id = obj.link_id;
            link_id = parseInt(link_id);
            this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(link_id);
            this.router.navigate(['/agent_details/', this.encypt_data])
            .then(() => {
              window.location.reload();
            });
          }
        }

      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

}
