import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";
import { AgentsService } from "../_service/agents.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.css']
})
export class AgentDetailsComponent implements OnInit {
  agent_id:string = '';
  encypt_data:string = '';
  decrypt_data:string = '';
  encrypt_redirect_id: any = '';

  agentDetails: any = {};
  myGroupList: any = [];
  myListing: any = [];
  myCard: any = [];
  settingsDetails: any = [];

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  dtOptions2: DataTables.Settings = {};
  dtTrigger2 = new Subject();

  dtOptions3: DataTables.Settings = {};
  dtTrigger3 = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private _EncryptdecryptService: EncryptdecryptService,
    private _AgentsService: AgentsService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.encypt_data = this.activatedRoute.snapshot.params.agent_id;
    // console.log('Agent id: : ',this.agent_id);
    // this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(this.agent_id);
    this.agent_id = this._EncryptdecryptService.decryptUsingAES256(this.encypt_data);
    // console.log('== ',this.encypt_data, ' && ', this.decrypt_data );

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };

    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };

    this.dtOptions3 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };

    this.getCustomerDetails();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
  }

  getCustomerDetails(){
    /** spinner starts on init */
    this.spinner.show();
    let params = {
      agent_id: this.agent_id
    }
    this._AgentsService.getAgentsDetails(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.agentDetails = response.response;

          this.myGroupList = this.agentDetails.my_group_list;
          this.dtTrigger.next();

          this.myListing = this.agentDetails.my_listing;
          this.dtTrigger2.next();

          this.myCard = this.agentDetails.my_card;
          this.dtTrigger3.next();

          this.settingsDetails = this.agentDetails.settings_details;
        }
        console.log('response :: ',this.myGroupList);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

  redirectTo(flag,id){
    id = parseInt(id);
    this.encrypt_redirect_id = this._EncryptdecryptService.encryptUsingAES256(id);
    if(flag==='Group'){
      this.router.navigate(['/group_details/', this.encrypt_redirect_id]);
    }
    if(flag==='Card' || flag==='Listing'){
      this.router.navigate(['/listing_details/', this.encrypt_redirect_id]);
    } 
  }
}
