import { Component, OnInit } from '@angular/core';
import { GroupsService } from "../_service/groups.service";
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EncryptdecryptService } from "../../common/_service/encryptdecrypt.service";

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.css'],
  providers: [GroupsService]
})
export class GroupsListComponent implements OnInit {

  encypt_data:string = '';

  dtOptions: DataTables.Settings = {};

  groupList: any = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger = new Subject();

  constructor(
    private _GroupsService: GroupsService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _EncryptdecryptService: EncryptdecryptService,
  ) { }

  ngOnInit(): void {
    this.groupList = [];
    /** spinner starts on init */
    this.spinner.show();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order : []
    };
    this.getGroupsList();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getGroupsList() {
    let params = {};
    this._GroupsService.getGroupsList(params).subscribe((response) => {
        this.spinner.hide();
        if(response.status === true){
          this.groupList = response.response.group_list;
          this.dtTrigger.next();
          //console.log('==>> ',response.response);
        }
        console.log('Group List : ', this.groupList);
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
    
  }

  redirctTo(id){
    id = parseInt(id);
    this.encypt_data = this._EncryptdecryptService.encryptUsingAES256(id);
    this.router.navigate(['/group_details/', this.encypt_data]);
  }

}
