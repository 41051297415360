import { Injectable } from '@angular/core';
import { Router, CanActivate, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  currentRoute: any;
  url: string;

  constructor(private _router: Router) { }

  canActivate(): boolean {
    
    if (sessionStorage.getItem('_token') !== null) {
      return true;
    } else {
      this._router.navigate(['login']);
      return false;
    }
  
  }
}
