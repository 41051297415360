import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentService } from '../_service/content.service';

@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.css']
})
export class StaticContentComponent implements OnInit {

  ContentEditForm: FormGroup;
  submitted = false;
  content_text: any = {};
  back_url: any;
  page_content_for: any = '';
  is_visiable : boolean = false;

  editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '400px',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
          {class: 'arial', name: 'Arial'},
          {class: 'times-new-roman', name: 'Times New Roman'},
          {class: 'calibri', name: 'Calibri'},
          {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['bold', 'italic']
      ]
  };


  constructor(
    private formBuilder: FormBuilder,
    private _ContentService: ContentService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private _activatedRoute: ActivatedRoute,
    //private _EncryptdecryptService: EncryptdecryptService
  ) { }

  page : any = this._activatedRoute.snapshot.params.page;

  ngOnInit(): void {
    //console.log(this.page);
    this.setPageName();
    this.ContentEditForm = this.formBuilder.group({
      page_content: ['', Validators.required]
    });
    this.getStaticContent();
  }

  get getContentEditFormVal() { return this.ContentEditForm.controls; }

  getStaticContent() {
    let params = {'content_type':this.page}
    this.spinner.show();
    this._ContentService.getStaticContent(params).subscribe((response)=>{
       if(response.status === true) {
          //console.log(response.response);
          this.content_text = response.response;
          this.ContentEditForm.controls['page_content'].setValue(this.content_text.content);
          this.toastr.success(response.status_msg);
       } else {
          this.toastr.info(response.status_msg);
       }
       this.spinner.hide();
     },
     (error)=>{
       console.log(error);
       this.spinner.hide();
     }
    );
  }

  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.ContentEditForm.invalid) {
        return;
    }
    //console.log(this.ContentEditForm.value);

    /** spinner starts on init */
    this.spinner.show();
    const content = this.ContentEditForm.value.page_content;

    let params = {
      "content": content,
      "content_type" : this.page
    }
    console.log('params : ', params);

    this._ContentService.staticContent(params).subscribe((response) => {
        //console.log('response : ', response.status);
        if(response.status === 1){

          this.spinner.hide();
          this.toastr.success(response.status_msg);
        }
        else{
          this.spinner.hide();
          this.toastr.info(response.status_msg);
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }

  setPageName(){
    switch(this.page){
      case 'terms-n-conditions': {
        this.page_content_for = 'Terms and Conditions';
        break;
      }
      case 'privacy-policy': {
        this.page_content_for = 'Privacy Policy';
        break;
      }
      case 'payment-policy': {
        this.page_content_for = 'Payment Policy';
        break;
      }
      case 'about-us': {
        this.page_content_for = 'About Us';
        break;
      }
      default: {
        this.page_content_for = 'Terms and Conditions';
        break;
      }
    }
  }


}
